import { ref } from 'vue';
import { API_URL } from '../../config.js';

const reservaService = (() => {
  const reservas = ref([]);
  const error = ref(null);
  const axios = require('axios');

  const instance = axios.create({
    baseURL: API_URL,
  });

  // Agregar interceptor para incluir token en las cabeceras
  instance.interceptors.request.use(
    config => {
      const token = localStorage.getItem('jwt-token');
      if (token) {
        config.headers['Authorization'] = 'Bearer ' + token;
      }
      return config;
    },
    error => {
      return Promise.reject(error);
    }
  );

  const sendVerificationEmail = async (data) => {
    try {
      const response = await instance.post('/Reserva/send-verification-email', data);
      return response.data;
    } catch (error) {
      console.error('Error:', error);
      return null;
    }
  };

  const createReservation = async (data) => {
    try {
      const response = await instance.post('/Reserva/create-reservation', data);
      return response.data;
    } catch (error) {
      console.error('Error:', error);
      return null;
    }
  };

  const deleteReservation = async (reservationId) => {
    try {
      const response = await instance.delete(`/Reserva/${reservationId}`);
      return response.data;  // Retornamos el mensaje de éxito del servidor
    } catch (error) {
      console.error('Error al eliminar la reserva:', error);
      throw new Error('No se pudo eliminar la reserva.');
    }
  };


  const getDisponibilidad = async (servicioId) => {
    try {
      const response = await instance.get(`/Reserva/get-disponibilidad/${servicioId}`);
      return response.data;
    } catch (error) {
      console.error('Error al obtener disponibilidad:', error);
      throw error;
    }
  };


  const getReservations = async () => {
    try {
      const response = await instance.get('/Reserva/get-reservas-by-tenant');
      reservas.value = response.data;
      return response.data;
    } catch (error) {
      console.error('Error:', error);
      return null;
    }
  };

  const getTimesForDate = async (date) => {
    try {
        const response = await instance.get(`/Reserva/get-times-for-date/${date.toISOString().split('T')[0]}`);
        if (response.status === 200) {
            return response.data;
        } else {
            console.error('Error al obtener los horarios disponibles:', response.statusText);
            return [];
        }
    } catch (error) {
        console.error('Error al obtener los horarios disponibles:', error);
        throw error;
    }
};

  const getReservaById = async (id) => {
    try {
      const response = await instance.get(`/Reserva/${id}`);
      return response.data;
    } catch (error) {
      console.error('Error:', error);
      return null;
    }
  };

  const getAvailableDates = async () => {
    try {
      const response = await instance.get('/Reserva/get-available-dates');
      if (response.status === 200) {
        return response.data;
      } else {
        error.value = 'No se pudieron obtener las fechas disponibles.';
        return null;
      }
    } catch (err) {
      console.error('Error al obtener fechas disponibles:', err);
      error.value = err.message;
      return null;
    }
  };
  const getAvailableDatesMonth = async () => {
    try {
      const response = await instance.get('/Reserva/get-available-dates-month');
      if (response.status === 200) {
        return response.data;
      } else {
        error.value = 'No se pudieron obtener las fechas disponibles.';
        return null;
      }
    } catch (err) {
      console.error('Error al obtener fechas disponibles:', err);
      error.value = err.message;
      return null;
    }
  };
  const updateReserva = async (id, data) => {
    try {
      const response = await instance.put(`/Reserva/update-reservation/${id}`, data);
      return response.data;
    } catch (error) {
      console.error('Error al actualizar la reserva:', error);
      if (error.response && error.response.data) {
        error.value = error.response.data; // Manejo del error específico del servidor
      } else {
        error.value = 'Error al actualizar la reserva.';
      }
      return null;
    }
  };


  return {
    reservas,
    error,
    sendVerificationEmail,
    createReservation,
    updateReserva,  // Agregar el nuevo método
    getAvailableDatesMonth,
    getReservations,
    getReservaById,
    deleteReservation,
    getDisponibilidad,
    getAvailableDates,
    getTimesForDate
  };
})();

export default reservaService;
