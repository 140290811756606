<template>
    <div class="grid grid-rows-1 gap-3">
      <!-- <div class="inline-block min-w-full py-2 sm:px-6 lg:px-8">
        <router-link to="/dashboard">
          <button
            type="button"
            class="px-4 py-2 text-sm font-medium text-white bg-[#3edfa9] border border-gray-200 rounded-lg hover:bg-[#ffe068]"
          >
            Volver atrás
          </button>
        </router-link>
      </div> -->
  
      <div class="inline-block min-w-full py-2 sm:px-6 lg:px-8">
        <!-- <h1
          class="mb-4 text-2xl font-bold tracking-tight leading-none text-gray-900 md:text-2xl lg:text-2xl text-center"
        >
          Lista de Clientes
        </h1> -->
  
        <!-- Filtros -->
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-4">
          <input
            type="text"
            v-model="nombreFiltro"
            placeholder="Filtrar por nombre"
            class="form-control block px-3 py-2 border border-gray-300 rounded-md text-gray-700 bg-white appearance-none cursor-pointer focus:outline-none focus:ring-1 focus:ring-blue-500"
          />
          <input
            type="text"
            v-model="celularFiltro"
            placeholder="Filtrar por celular"
            class="form-control block px-3 py-2 border border-gray-300 rounded-md text-gray-700 bg-white appearance-none cursor-pointer focus:outline-none focus:ring-1 focus:ring-blue-500"
          />
  
          <router-link :to="{ path: '/formcliente', query: { isAdmin: true } }">
            <button
              type="button"
              class="focus:outline-none text-white bg-[#3edfa9] hover:bg-[#ffe068] focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-2 md:px-5 py-2.5"
            >
              <svg
                class="w-6 h-6 inline-block text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M12 7.757v8.486M7.757 12h8.486M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                />
              </svg>
              Agregar Cliente
            </button>
          </router-link>
        </div>
  
        <!-- Tabla de clientes -->
        <div class="overflow-x-auto">
          <table class="min-w-full text-left text-sm font-light">
            <thead class="text-xs text-gray-700 uppercase bg-[#96ffdf]">
              <tr>
                <th scope="col" class="px-6 py-4">Nombre y Apellido</th>
                <th scope="col" class="px-6 py-4">Celular</th>
                <th scope="col" class="px-6 py-4">Email</th>
                <th scope="col" class="px-6 py-4">Concepto</th>
                <th scope="col" class="px-6 py-4">Acción</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="cliente in filteredClientes"
                :key="cliente.id"
                class="odd:bg-white even:bg-gray-50 border-b"
              >
                <td class="whitespace-nowrap px-6 py-4">{{ cliente.nombreApellido }}</td>
                <td class="whitespace-nowrap px-6 py-4">{{ cliente.celular }}</td>
                <!-- <td class="whitespace-nowrap px-6 py-4">{{ cliente.telefonoFijo }}</td> -->
                <td class="whitespace-nowrap px-6 py-4">{{ cliente.email }}</td>
                <td class="whitespace-nowrap px-6 py-4">{{ cliente.concepto }}</td>
                <td class="whitespace-nowrap px-6 py-4">
                  <div class="flex space-x-2">
                    <button
                      class="text-blue-600 hover:text-blue-800 focus:outline-none"
                      @click="editCliente(cliente)"
                    >
                      <i class="fas fa-edit"></i>
                    </button>
                    <button
                      class="text-red-600 hover:text-red-800 focus:outline-none"
                      @click="deleteModal(cliente.id)"
                    >
                      <i class="fas fa-trash-alt"></i>
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="flex flex-col justify-start md:flex-row md:justify-center lg:flex-row lg:justify-center lg:gap-8 md:gap-8">
        <div><p class="text-sm lg:p-2">Num. de clientes: {{ filteredClientes.length }}</p></div>
      </div>
  
      <Modal message="cliente" @confirm="deleteCliente" ref="modalComponent" />
    </div>
  </template>
  
  <script>
  import clienteService from "../../composables/api/clienteService.js";
  import { ref, onMounted, computed } from "vue";
  import { useRouter } from "vue-router";
  import Modal from "../modalConfirmar/ModalConfirmar.vue";
  
  export default {
    components: {
      Modal,
    },
    setup() {
      const clientes = ref([]);
      const nombreFiltro = ref("");
      const celularFiltro = ref("");
      const modalComponent = ref(null);
      const modal = ref(null);
      const router = useRouter();
  
      onMounted(async () => {
        fetchClientes();
      });
  
      const fetchClientes = async () => {
        try {
          clientes.value = await clienteService.getClientes();
        } catch (error) {
          console.error("Error al obtener clientes:", error);
        }
      };
  
      const filteredClientes = computed(() => {
        return clientes.value.filter((cliente) => {
          const nombreMatch = cliente.nombreApellido.toLowerCase().includes(nombreFiltro.value.toLowerCase());
          const celularMatch = cliente.celular && cliente.celular.toString().includes(celularFiltro.value);
          return nombreMatch && (!celularFiltro.value || celularMatch);
        });
      });
  
      const editCliente = (cliente) => {
        router.push({
          path: "/formcliente",
          query: { id: cliente.id },
        });
      };
  
      const deleteCliente = async (clienteId) => {
        try {
          await clienteService.deleteCliente(clienteId);
          fetchClientes();
          // modal.value.hide();
        } catch (error) {
          console.error("Error al eliminar cliente:", error);
        }
      };
  
      const deleteModal = async (clienteId) => {
        modal.value = await modalComponent.value.getModal(clienteId);
      };
  
      return {
        clientes,
        nombreFiltro,
        celularFiltro,
        filteredClientes,
        modalComponent,
        modal,
        editCliente,
        deleteCliente,
        fetchClientes,
        deleteModal,
      };
    },
  };
  </script>
  