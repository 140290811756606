import { ref } from 'vue';
import { API_URL } from '../../config.js';

const clienteService = (() => {
  const clientes = ref([]);
  const error = ref(null);
  const axios = require('axios');

  const instance = axios.create({
    baseURL: API_URL,
  });

  instance.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem('jwt-token');
      if (token) {
        config.headers['Authorization'] = 'Bearer ' + token;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const getClientes = async () => {
    try {
      const response = await instance.get('/Cliente');
      clientes.value = response.data;
      return response.data;
    } catch (error) {
      console.error('Error al obtener clientes:', error);
      return null;
    }
  };

  const getClienteById = async (id) => {
    try {
      const response = await instance.get(`/Cliente/${id}`);
      return response.data;
    } catch (error) {
      console.error('Error al obtener cliente:', error);
      return null;
    }
  };

  const addCliente = async (data) => {
    try {
      const response = await instance.post('/Cliente', data);
      return response.data;
    } catch (error) {
      console.error('Error al crear cliente:', error);
      return null;
    }
  };

  const updateCliente = async (id, data) => {
    try {
      const response = await instance.put(`/Cliente/${id}`, data);
      return response.data;
    } catch (error) {
      console.error('Error al actualizar cliente:', error);
      return null;
    }
  };

  const deleteCliente = async (clienteId) => {
    try {
      const response = await instance.delete(`/Cliente/${clienteId}`);
      return response.data;
    } catch (error) {
      console.error('Error al eliminar cliente:', error);
      throw new Error('No se pudo eliminar el cliente.');
    }
  };

  return {
    clientes,
    error,
    getClientes,
    getClienteById,
    addCliente,
    updateCliente,
    deleteCliente,
  };
})();

export default clienteService;
