<template>
  <div class="landing-page bg-gray-50 min-h-screen">
    <header class="relative">
      <img :src="headerImage" alt="Banner" class="w-full h-48 object-cover object-center"> <!-- Altura reducida a 48 -->
      <div class="absolute inset-0 bg-black opacity-40"></div> <!-- Ajustamos la opacidad -->
      <div class="absolute inset-0 flex items-center justify-center">
        <h1 class="text-3xl font-bold text-white tracking-wide">{{ businessName }}</h1>
        <!-- Tamaño del texto reducido -->
      </div>
    </header>

    <main class="container mx-auto py-16">
      <!-- Etapa 1: Selección de servicio, fecha y hora -->
      <div v-if="step === 1" class="bg-white p-8 shadow-lg rounded-lg max-w-lg mx-auto">
        <h2 class="text-2xl font-bold mb-8 text-center text-gray-800">Reserva tu Servicio</h2>

        <!-- Botón de selección de servicio -->
        <div class="text-center">
          <button @click="toggleModal"
            class="flex items-center justify-between w-full p-4 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100">
            <span class="text-xl font-bold text-gray-900">
              <i class="fas fa-hand-holding-usd mr-2"></i>
              Elegir servicio
            </span>
          </button>
          <div class="flex flex-col items-center mt-4">
            <i class="fas fa-arrow-up text-indigo-500"></i>
            <p class="text-sm text-gray-500 mt-2">Presiona para elegir</p>
          </div>
        </div>

        <!-- Modal para seleccionar servicio -->
        <div v-if="showModal" class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div class="bg-white rounded-lg shadow-lg p-6 max-w-lg w-full">
            <h3 class="text-2xl font-bold mb-4 text-center">Selecciona un servicio</h3>
            <div class="grid grid-cols-1 gap-4">
              <div v-for="service in services" :key="service.id"
                class="border p-4 rounded-lg shadow hover:bg-gray-100 cursor-pointer" @click="selectService(service)">
                <h4 class="text-lg font-bold">{{ service.nombre }}</h4>
                <p class="text-gray-500">Precio: ${{ service.precio }}</p>
              </div>
            </div>
            <button @click="toggleModal"
              class="mt-6 bg-red-500 text-white font-bold py-2 px-4 rounded w-full">Cerrar</button>
          </div>
        </div>

        <!-- Información del servicio seleccionado -->
        <div v-if="selectedServiceName && selectedServicePrice" class="mt-6">
          <p class="text-lg">Servicio seleccionado: <strong>{{ selectedServiceName }}</strong></p>
          <p class="text-lg">Precio: <strong>${{ selectedServicePrice }}</strong></p>
        </div>

        <!-- Selección de fecha -->
        <div v-if="selectedServiceName && selectedServicePrice && availableSlots && availableSlots.length"
          class="text-center mb-8">
          <h3 class="text-lg font-bold mb-2">Elegir fecha</h3>
          <div class="flex gap-4 justify-center items-center">
    <button id="list-view-btn" class="view-btn active" @click="setAvalaibleDates(true)">
        <i class="fas fa-list"></i> 
    </button>
    <button id="grid-view-btn" class="view-btn" @click="setAvalaibleDates(false)">
        <i class="fas fa-th"></i> 
    </button>
</div>
          <div class="flex justify-center items-center space-x-2 flex-wrap gap-2">
            <button v-for="(date, index) in dates" :key="index" @click="selectDate(date)"
              :class="['p-4 border rounded-lg cursor-pointer', selectedDate === date ? 'bg-blue-500 text-white' : 'bg-gray-100']">
              <p class="text-sm">{{ date.day }}</p>
              <p class="text-lg font-bold">{{ date.date }}</p>
            </button>
          </div>
        </div>

        <!-- Selección de horario -->
        <div v-if="selectedDate" class="text-center mb-8">
          <h3 class="text-lg font-bold mb-2">Elegir horario</h3>
          <div class="flex justify-center items-center flex-wrap gap-4">
            <div>
              <h4 class="font-semibold mb-2">Por la mañana</h4>
              <div class="flex  flex-wrap gap-2">
                <button v-for="(time, index) in morningTimes" :key="index" @click="selectTime(time)"
                  :class="['px-4 py-2 border rounded-lg cursor-pointer', selectedTime === time ? 'bg-blue-500 text-white' : 'bg-gray-100']">
                  {{ time }}
                </button>
              </div>
            </div>
            <div>
              <h4 class="font-semibold mb-2">Por la tarde</h4>
              <div class="flex  flex-wrap gap-2">
                <button v-for="(time, index) in afternoonTimes" :key="index" @click="selectTime(time)"
                  :class="['px-4 py-2 border rounded-lg cursor-pointer', selectedTime === time ? 'bg-blue-500 text-white' : 'bg-gray-100']">
                  {{ time }}
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- Botón continuar -->
        <button v-if="selectedDate && selectedTime" @click="advanceStep"
          class="mt-6 w-full py-2 px-4 bg-indigo-600 hover:bg-indigo-700 text-white font-bold rounded-lg transition duration-300">
          Continuar
        </button>
      </div>

      <!-- Etapa 2: Resumen de reserva -->
      <div v-if="step === 2" class="bg-white p-6 shadow-lg rounded-lg max-w-lg mx-auto">
        <div class="flex justify-between items-center border-b pb-2 mb-4">
          <h3 class="text-xl font-bold">Turno a Reservar</h3>
          <p class="text-xl font-bold text-blue-500">${{ selectedServicePrice }}</p>
        </div>
        <div class="bg-gray-100 p-4 rounded-lg mb-4">
          <h4 class="text-lg font-bold text-purple-500">Turno 1</h4>
          <p class="text-sm mt-2 font-semibold">{{ selectedServiceName }} <span class="text-gray-500">(20 min)</span>
          </p>
          <p class="text-sm text-gray-600">{{ location }}</p>
          <p class="text-sm text-gray-600 mt-2">Fecha: {{ formattedDate }}</p>
          <p class="text-sm text-gray-600">Hora: {{ selectedTime }}</p>
        </div>

        <!-- Campos de nombre, email, y WhatsApp -->
        <div class="mb-4">
          <input v-model="reservationData.nombre" type="text" placeholder="Nombre"
            class="w-full p-2 border border-gray-300 rounded-lg mb-4" required>
          <input v-model="reservationData.email" type="email" placeholder="Correo Electrónico"
            class="w-full p-2 border border-gray-300 rounded-lg mb-4" required>
          <input v-model="reservationData.whatsapp" type="text" placeholder="WhatsApp"
            class="w-full p-2 border border-gray-300 rounded-lg mb-4" required>
          <input v-model="reservationData.aclaraciones" type="text"
            placeholder="Aclaraciones para el profesional/empresa..."
            class="w-full p-2 border border-gray-300 rounded-lg">
        </div>

        <div class="flex justify-between items-center">
          <button @click="generateRandomCode"
            class="w-full py-2 px-4 bg-yellow-500 hover:bg-yellow-600 text-white font-bold rounded-lg transition duration-300">
            RESERVAR
          </button>
        </div>
      </div>

      <!-- Ventana de confirmación con código -->
      <div v-if="showConfirmationModal"
        class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
        <div class="bg-white rounded-lg shadow-lg p-6 max-w-lg w-full">
          <h3 class="text-2xl font-bold mb-4 text-center">Confirmar Reserva</h3>
          <p class="text-center mb-4">Introduce el código de 4 dígitos para confirmar la reserva.</p>
          <p class="text-center text-xl font-bold">{{ randomCode }}</p>
          <div class="text-center mt-4">
            <input v-model="enteredCode" type="text" placeholder="Código de 4 dígitos"
              class="w-1/2 p-2 border border-gray-300 rounded-lg text-center">
          </div>
          <div class="flex justify-around mt-4">
            <button @click="validateCode"
              class="bg-green-500 text-white font-bold py-2 px-4 rounded-lg">Confirmar</button>
            <button @click="showConfirmationModal = false"
              class="bg-red-500 text-white font-bold py-2 px-4 rounded-lg">Cancelar</button>
          </div>
        </div>
      </div>

      <!-- Etapa 3: Resumen final -->
      <div v-if="step === 3" class="bg-white p-6 shadow-lg rounded-lg max-w-lg mx-auto">
        <h2 class="text-2xl font-bold mb-6 text-center">Reserva Confirmada</h2>
        <div class="bg-gray-100 p-4 rounded-lg mb-4">
          <h4 class="text-lg font-bold text-purple-500">Resumen de tu Reserva</h4>
          <p class="text-sm mt-2 font-semibold">{{ selectedServiceName }} <span class="text-gray-500">(20 min)</span>
          </p>
          <p class="text-sm text-gray-600">{{ location }}</p>
          <p class="text-sm text-gray-600 mt-2">Fecha: {{ formattedDate }}</p>
          <p class="text-sm text-gray-600">Hora: {{ selectedTime }}</p>
          <p class="text-sm text-gray-600">Precio: ${{ selectedServicePrice }}</p>
        </div>
        <div class="text-center">
          <p class="text-xl font-bold">¡Gracias por tu reserva!</p>
        </div>
      </div>
    </main>

    <footer class="bg-gray-800 text-white py-6">
      <div class="container mx-auto text-center">
        <p class="text-sm">&copy; 2024 {{ businessName }}. Todos los derechos reservados.</p>
        <div class="mt-4">
          <a href="#" class="text-indigo-400 hover:text-indigo-300 transition duration-300">Política de Privacidad</a>
          <span class="mx-2">|</span>
          <a href="#" class="text-indigo-400 hover:text-indigo-300 transition duration-300">Términos y Condiciones</a>
        </div>
      </div>
    </footer>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue';
import { useRoute } from 'vue-router';
import reservaService from "../../composables/api/reservaService.js";
import { API_URL } from '../../config.js';

const route = useRoute();

const businessName = ref('Tu Empresa');
const headerImage = ref('https://condeseguros.com.ar/wp-content/uploads/2020/10/logo-gestoria-1024x683.jpg');
const tenantId = ref(0);
const step = ref(1); // Controla las etapas

const selectedDate = ref('');
const selectedTime = ref('');
const selectedService = ref(null);
const selectedServiceName = ref('');
const selectedServicePrice = ref('');
const location = ref('Av Recta Martinolli 6761, portón al lado de la farmacia');
const services = ref([]);
const showModal = ref(false);
const showConfirmationModal = ref(false); // Controla el modal de confirmación
const authEmail = ref('');
const randomCode = ref(null); // Código generado
const enteredCode = ref(''); // Código ingresado por el usuario
const availableSlots = ref([]); // Asegúrate de que availableSlots esté inicializado como una lista vacía

// Inicializamos correctamente reservationData
const reservationData = ref({
  nombre: '',
  email: '',
  whatsapp: '',
  aclaraciones: ''
});

// Fechas y horarios predefinidos
const dates = ref([
  { day: 'sáb', date: 24 },
  { day: 'dom', date: 25 },
  { day: 'lun', date: 26 },
  { day: 'mar', date: 27 },
  { day: 'mié', date: 28 },
]);

const morningTimes = ref(['8:00', '10:15', '10:30', '11:00']);
const afternoonTimes = ref(['12:00', '12:15', '12:30']);

const fetchTenantDetails = async (tenantName) => {
  try {
    // const response = await fetch(`https://localhost:44312/Tenant/GetByName/${tenantName}`);
    const response = await fetch(`${API_URL}/Tenant/GetByName/${tenantName}`);
    if (response.ok) {
      const tenant = await response.json();
      businessName.value = tenant.nombreEmpresa || 'Nombre de la Empresa';
      headerImage.value = tenant.bannerUrl || 'https://defaultbanner.url/banner.jpg';
      tenantId.value = tenant.id
    } else {
      alert('Error al obtener los detalles del tenant.');
    }
  } catch (error) {
    console.error('Error:', error);
    alert('Error al obtener los detalles del tenant.');
  }
};

const fetchAvailableDates = async () => {
  try {
    const response = await reservaService.getAvailableDates();
    if (response.ok) {
      const today = new Date();
      const futureDates = response.data.filter(date => new Date(date) > today);
      dates.value = futureDates.map(date => ({
        day: date.toLocaleDateString('es', { weekday: 'short' }),
        date: date.getDate(),
        month: date.getDate()
      }));
    } else {
      alert('Error al obtener las fechas disponibles.');
    }
  } catch (error) {
    console.error('Error al obtener fechas disponibles:', error);
    alert('Error al obtener las fechas disponibles.');
  }
};




const fetchServicesByTenantName = async (tenantName) => {
  try {
    // const response = await fetch(`https://localhost:44312/Servicio/GetByTenantName/${tenantName}`);
    const response = await fetch(`${API_URL}/Servicio/GetByTenantName/${tenantName}`);
    if (response.ok) {
      services.value = await response.json();
    } else {
      alert('Error al obtener los servicios.');
    }
  } catch (error) {
    console.error('Error:', error);
    alert('Error al obtener los servicios.');
  }
};

const setAvalaibleDates = async (deflt) => {
  var availableDates = null
  if(deflt){
   availableDates = await reservaService.getAvailableDates();
    
  }else{
   availableDates = await reservaService.getAvailableDatesMonth();

  }
  dates.value = availableDates.map(dateString => {
    const date = new Date(dateString);
    return {
      day: date.toLocaleDateString('es', { weekday: 'short' }),
      date: date.getDate(),
      month: date.getMonth()
    };
  });
}

onMounted(async () => {

  setAvalaibleDates(true)
  console.log("Días hábiles recibidos:", dates.value);

  const tenantName = route.params.tenantName || 'defaultTenant';
  fetchTenantDetails(tenantName);
  fetchServicesByTenantName(tenantName);
});

const toggleModal = () => {
  showModal.value = !showModal.value;
};


const selectService = async (service) => {
  selectedService.value = service.id;
  selectedServiceName.value = service.nombre;
  selectedServicePrice.value = service.precio;
  toggleModal();

  try {
    availableSlots.value = await reservaService.getDisponibilidad(service.id); // Obtener slots disponibles
  } catch (error) {
    console.error('Error al obtener los slots disponibles:', error);
  }
};


const fetchTimesForDate = async (selectedDate) => {
  try {
    const response = await reservaService.getTimesForDate(selectedDate);
    
    if (response.length > 0) {
      const now = new Date();
      const futureTimes = response.filter(time => {
        const timeDate = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate(), time.split(':')[0], time.split(':')[1]);
        return timeDate > now;
      });
      if (selectedDate.getDate() === now.getDate()) {
        morningTimes.value = futureTimes.filter(time => parseInt(time.split(':')[0]) < 12);
        afternoonTimes.value = futureTimes.filter(time => parseInt(time.split(':')[0]) >= 12);
      } else {
        morningTimes.value = response.filter(time => parseInt(time.split(':')[0]) < 12);
        afternoonTimes.value = response.filter(time => parseInt(time.split(':')[0]) >= 12);
      }
    } else {
      
      alert('Error al obtener los horarios.');
    }
  } catch (error) {
    console.error('Error al obtener horarios:', error);
    alert('Error al obtener los horarios.');
  }
};

const selectDate = (date) => {
  const selectedDateObj = new Date();
  selectedDateObj.setDate(date.date);
  selectedDateObj.setMonth(date.month);

  selectedDate.value = selectedDateObj;
  fetchTimesForDate(selectedDateObj);
};


const selectTime = (time) => {
  selectedTime.value = time;
};

const advanceStep = () => {
  step.value++;
};

// Función para generar código aleatorio de 4 dígitos
const generateRandomCode = () => {

  if (!reservationData.value.nombre || !reservationData.value.email || !reservationData.value.whatsapp) {
    alert('Por favor, completa todos los campos obligatorios: Nombre, Correo Electrónico y WhatsApp.');
    return;
  }

  randomCode.value = Math.floor(1000 + Math.random() * 9000); // Genera un número aleatorio entre 1000 y 9999
  showConfirmationModal.value = true;
};

// Función para validar el código ingresado
const validateCode = () => {
  if (parseInt(enteredCode.value) === randomCode.value) {
    submitReservation();
  } else {
    alert('Código incorrecto. Se ha generado uno nuevo.');
    generateRandomCode(); // Genera un nuevo código si es incorrecto
  }
};

const submitReservation = async () => {
  try {
    // Asegurarse de que la fecha y la hora estén seleccionadas
    if (selectedDate.value == "" || selectedDate.value.date == "") {
      alert('Por favor, selecciona una fecha para continuar.');
      throw new Error('Fecha seleccionada no está definida');
    }

    if (selectedTime.value == "") {
      alert('Por favor, selecciona una hora para continuar.');
      throw new Error('Hora seleccionada no está definida');
    }
    const sDate = new Date(selectedDate.value);

// Extraer el año, mes y día
const day = sDate.getDate().toString().padStart(2, '0');
const currentMonth = (sDate.getMonth() + 1).toString().padStart(2, '0'); // Los meses son 0 indexados
const currentYear = sDate.getFullYear();

// Extraer las horas y minutos
const hours = sDate.getHours().toString().padStart(2, '0');
const minutes = sDate.getMinutes().toString().padStart(2, '0');

// Construir la cadena de fecha y hora en el formato ISO 8601
const dateStr = `${currentYear}-${currentMonth}-${day}T${hours}:${minutes}:00`;
console.log(dateStr);

const appointmentDate = new Date(dateStr);
if (isNaN(appointmentDate.getTime())) {
  throw new Error('Valor de fecha inválido');
}
   
    const data = {
      nombre: reservationData.value.nombre,
      email: reservationData.value.email,
      servicioId: selectedService.value,
      tenantId: tenantId.value,
      appointmentTime: appointmentDate.toISOString(),
      aclaraciones: reservationData.value.aclaraciones,
      whatsapp: reservationData.value.whatsapp
    };

    // Llamada al servicio para crear la reserva
    const response = await reservaService.createReservation(data);
    if (response) {
      showConfirmationModal.value = false;
      advanceStep();
    } else {
      throw new Error('Error al realizar la reserva');
    }
  } catch (error) {
    console.error('Error al enviar la reserva:', error);
    alert('Error: ' + error.message);
  }
};







// Formato de fecha y hora
const formattedDate = computed(() => {
  let dat = new Date(selectedDate.value)
  const day = dat.getDate().toString().padStart(2, '0');
const currentMonth = (dat.getMonth() + 1).toString().padStart(2, '0')
  return `${day}/${currentMonth}`;
});

const formatDate = (slot) => {
  const date = new Date(slot);
  return date.toLocaleDateString(); // Formato de la fecha, lo puedes ajustar según sea necesario
};

const formatTime = (slot) => {
  const date = new Date(slot);
  return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }); // Formato de la hora
};


const selectSlot = (slot) => {
  const date = new Date(slot);
  selectedDate.value = date.toLocaleDateString();
  selectedTime.value = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
};


const authenticateUserWithEmail = async () => {
  alert("Autenticación con correo iniciada para: " + authEmail.value);
};

const authenticateWithGoogle = async () => {
  alert("Autenticación con Google iniciada.");
};
</script>

<style scoped>
.landing-page {
  font-family: 'Inter', sans-serif;
}

button {
  transition: all 0.3s ease;
}

button:hover {
  transform: scale(1.05);
}
</style>
