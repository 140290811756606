<template>
  <div class="grid grid-flow-row gap-4">
    <div class="max-w-md p-6 bg-white border border-gray-200 rounded-lg shadow ">
      <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 ">
        Ingresos
      </h5>

      <form class="max-w-sm mx-auto">
        <div class="mb-5">
          <div class="form-group">
            <label for="descripcion">Descripción</label>
            <input type="text"
              class="form-control mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-300 focus:ring focus:ring-blue-200"
              id="descripcion" v-model="form.descripcion" required />
          </div>
          <!-- Campo de Método de Pago -->
          <div class="form-group">
            <label for="metodo-pago" class="text-black">Método de pago</label>
            <select
              class="form-control form-input mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-300 focus:ring focus:ring-blue-200"
              id="metodo-pago" v-model="form.metodoPago" required>
              <option value="">Seleccionar método de pago</option>
              <option value="efectivo">Efectivo</option>
              <option value="tarjeta">Tarjeta</option>
              <option value="transferencia">Transferencia</option>
            </select>
          </div>
          <div class="form-group">
            <label for="importe">Importe</label>
            <input type="number"
              class="form-control mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-300 focus:ring focus:ring-blue-200"
              id="importe" min="1" v-model="form.importe" required />
          </div>
          <div v-if="isNegativeImport">
            <p class="text-red-600">No ingresar valores negativos</p>
          </div>
          <div class="form-group" v-if="isAdmin">
            <label for="importe">Fecha</label>
            <input type="date"
              class="form-control mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-300 focus:ring focus:ring-blue-200"
              id="fechaRegistro" min="1" v-model="form.fechaRegistro" required />
          </div>
        </div>
        <button type="submit" @click.prevent="submitForm"
          class="text-black bg-green-300 hover:bg-green-600 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center ">
          Guardar
        </button>

        <router-link :to="isAdmin ? '/Ingresos' : '/caja'"
          class="ml-5 font-medium text-green-500 hover:underline">Cancelar</router-link>
      </form>
    </div>
  </div>
  <!--
  <div class="viewport">
    <div class="card p-4 mx-2 shadow-md overflow-hidden w-fit self-center">
      <div>
        <router-link
          :to="isAdmin ? '/Ingresos' : '/caja'"
          class="text-emerald-300 hover:text-emerald-600"
          >&lt; Volver atrás</router-link
        >
      </div>
      <h1 class="font-bold mb-2">Ingresos</h1>
      <form>
        <div class="form-group">
          <label for="descripcion">Descripción</label>
          <input
            type="text"
            class="form-control form-input mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-300 focus:ring focus:ring-blue-200"
            id="descripcion"
            v-model="form.descripcion"
            required
          />
        </div>
        <div class="form-group">
          <label for="importe">Importe</label>
          <input
            type="number"
            class="form-control form-input mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-300 focus:ring focus:ring-blue-200"
            id="importe"
            min="1"
            v-model="form.importe"
            required
          />
        </div>
        <div v-if="isNegativeImport">
          <p class="text-red-600">No ingresar valores negativos</p>
        </div>
        <div class="form-group" v-if="isAdmin">
          <label for="importe">Fecha</label>
          <input
            type="date"
            class="form-control form-input mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-300 focus:ring focus:ring-blue-200"
            id="importe"
            v-model="form.fechaRegistro"
            required
          />
        </div>
        <button
          type="submit"
          class="mt-3 bg-emerald-300 hover:bg-emerald-500 text-white font-bold py-2 px-4 rounded"
          @click.prevent="submitForm"
        >
          Guardar
        </button>
      </form>
    </div>
  </div>-->
</template>
<script>
// import cajaService from '../services/caja.service.js';
import cajaService from "../../composables/api/cajaService.js";
import ingresoService from "../../composables/api/ingresoService.js";
import { reactive, onMounted, ref } from "vue";
import { useRouter, useRoute } from "vue-router";

export default {
  setup() {
    const router = useRouter();
    const route = useRoute();
    const isAdmin = ref(false);
    let cajaAbierta = ref({});
    let isNegativeImport = ref(false);

    cajaAbierta = cajaService.getCajaAbierta();

    let form = ref({
      id: 0,
      descripcion: "",
      importe: null,
      cajaId: 0,
      fechaRegistro: null,
    });

    const submitForm = async () => {

      // Solo asignar cajaId si cajaAbierta existe y tiene un id
      if (cajaAbierta.value && cajaAbierta.value.id) {
        form.value.cajaId = cajaAbierta.value.id;
      } else {
        form.value.cajaId = null; // Asegurarse de que sea null si no hay caja abierta
      }

      if (form.value.importe < 1) {
        isNegativeImport.value = true;
        return;
      }
      if (form.value.fechaRegistro != "") {
        const fecha = new Date(form.value.fechaRegistro);
        const fechaFormateada = fecha.toISOString();
        form.value.fechaRegistro = fechaFormateada;
      }
      if (form.value.id == 0) {
        await ingresoService.addIngreso(form);
      } else {
        await ingresoService.editIngreso(form.value);
      }
      form.value = {};
      form.descripcion = "";
      form.importe = "";
      form.fechaRegistro = "";
      if (isAdmin.value) {
        router.push("/ingresos");
        return;
      }
      router.push("/caja");
    };

    onMounted(async () => {
      cajaAbierta.value = await cajaService.getCajaAbierta();
      const query = router.currentRoute.value.query;

      // Establecer la fecha de hoy por defecto en el campo de fechaRegistro
      const today = new Date();
      form.value.fechaRegistro = today.toISOString().split("T")[0]; // Obtener fecha en formato yyyy-MM-dd



      if (query.isAdmin === "true") {
        isAdmin.value = true;
      }
      if (query.id != null) {
        console.log(query);
        form.value.id = query.id;
        form.value = await ingresoService.getIngresoById(query.id);
      }
    });

    return {
      isNegativeImport,
      cajaAbierta,
      form,
      submitForm,
      isAdmin,
    };
  },
  name: "IngresoForm",
  props: {},
  components: {},
  created() { },
  data() {
    return {};
  },
  methods: {},
};
</script>
