<template>
  <div class="grid grid-rows-1 gap-3">
    <div class="inline-block min-w-full py-2 sm:px-6 lg:px-8">
      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-4">
        <input type="text" v-model="nombreFiltro" placeholder="Filtrar por nombre"
          class="form-control block px-3 py-2 border border-gray-300 rounded-md text-gray-700 bg-white appearance-none cursor-pointer focus:outline-none focus:ring-1 focus:ring-blue-500" />

        <router-link to="/formproducto">
          <button type="button"
            class="focus:outline-none text-white bg-[#3edfa9] hover:bg-[#ffe068] focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-2 md:px-5 py-2.5">
            <svg class="w-6 h-6 inline-block text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
              width="24" height="24" fill="none" viewBox="0 0 24 24">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M12 7.757v8.486M7.757 12h8.486M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
            </svg>
            Agregar Producto
          </button>
        </router-link>
      </div>

      <!-- Tabla de productos -->
      <div class="overflow-x-auto">
        <table class="min-w-full text-left text-sm font-light">
          <thead class="text-xs text-gray-700 uppercase bg-[#96ffdf]">
            <tr>
              <th scope="col" class="px-6 py-4">Nombre</th>
              <th scope="col" class="px-6 py-4">Descripción</th>
              <th scope="col" class="px-6 py-4">Precio</th>
              <th scope="col" class="px-6 py-4">Acción</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="producto in filteredProductos" :key="producto.id" class="odd:bg-white even:bg-gray-50 border-b">
              <td class="whitespace-nowrap px-6 py-4">{{ producto.nombre }}</td>
              <td class="whitespace-nowrap px-6 py-4">{{ producto.descripcion }}</td>
              <td class="whitespace-nowrap px-6 py-4">{{ producto.precio }}</td>
              <td class="whitespace-nowrap px-6 py-4">
                <div class="flex space-x-2">
                  <button class="text-blue-600 hover:text-blue-800 focus:outline-none" @click="editProducto(producto)">
                    <i class="fas fa-edit"></i>
                  </button>
                  <button class="text-red-600 hover:text-red-800 focus:outline-none" @click="deleteModal(producto.id)">
                    <i class="fas fa-trash-alt"></i>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div
      class="flex flex-col justify-start md:flex-row md:justify-center lg:flex-row lg:justify-center lg:gap-8 md:gap-8">
      <div>
        <p class="text-sm lg:p-2">Num. de productos: {{ filteredProductos.length }}</p>
      </div>
    </div>

    <Modal message="producto" @confirm="deleteProducto" ref="modalComponent" />
  </div>
</template>

<script>
import productoService from "../../composables/api/productoService.js";
import { ref, onMounted, computed } from "vue";
import { useRouter } from "vue-router";
import Modal from "../modalConfirmar/ModalConfirmar.vue";

export default {
  components: {
    Modal,
  },
  setup() {
    const productos = ref([]);
    const nombreFiltro = ref("");
    const modalComponent = ref(null);
    const modal = ref(null);
    const router = useRouter();

    onMounted(async () => {
      fetchProductos();
    });

    const fetchProductos = async () => {
      try {
        productos.value = await productoService.getProductos();
      } catch (error) {
        console.error("Error al obtener productos:", error);
      }
    };

    const filteredProductos = computed(() => {
      return productos.value.filter((producto) =>
        producto.nombre.toLowerCase().includes(nombreFiltro.value.toLowerCase())
      );
    });



    const editProducto = (producto) => {
      router.push({
        path: "/formproducto",
        query: { id: producto.id },
      });
    };

    const deleteProducto = async (productoId) => {
      try {
        await productoService.deleteProducto(productoId);
        fetchProductos();
        modal.value.hide();
      } catch (error) {
        console.error("Error al eliminar producto:", error);
      }
    };

    const deleteModal = async (productoId) => {
      modal.value = await modalComponent.value.getModal(productoId);
    };

    return {
      productos,
      nombreFiltro,
      filteredProductos,
      modalComponent,
      modal,
      editProducto,
      deleteProducto,
      fetchProductos,
      deleteModal,
    };
  },
};
</script>
