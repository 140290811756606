<template>
  <div class="grid grid-flow-row gap-4">
    <div class="flex justify-center min-height-auto">
      <h1
        class="text-3xl text-center font-bold leading-none tracking-tight text-gray-900 md:text-3xl lg:text-3xl"
      >
        Historial
      </h1>
    </div>

    <div class="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-3">
      <div>
        <label for="desde" class="block mb-2 text-gray-700 font-semibold"
          >Desde:</label
        >
        <input
          type="date"
          id="desde"
          v-model="fechaDesde"
          class="border-1 rounded-md px-3 py-2 w-full border border-gray-300"
        />
      </div>
      <div>
        <label for="hasta" class="block mb-2 text-gray-700 font-semibold"
          >Hasta:</label
        >
        <input
          type="date"
          id="hasta"
          v-model="fechaHasta"
          class="border-1 rounded-md px-3 py-2 w-full border border-gray-300"
        />
      </div>
      <div class="grid-flow-row content-end">
        <button
          type="button"
          @click="filtrarCajas"
          :disabled="loading"
          class="text-white bg-[#3edfa9] hover:bg-[#ffe068] inline-flex items-center px-5 py-2.5 rounded-lg"
        >
          <svg
            v-if="loading"
            aria-hidden="true"
            role="status"
            class="inline w-4 h-4 me-3 text-white animate-spin"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="#E5E7EB"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentColor"
            />
          </svg>
          Filtrar
        </button>
      </div>
    </div>

    <div v-if="loading" class="flex justify-center mt-6">
      <svg
        class="animate-spin h-8 w-8 text-blue-600"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle
          class="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          stroke-width="4"
        ></circle>
        <path
          class="opacity-25"
          fill="#3edfa9"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
      </svg>
    </div>
    <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
      <table
        v-if="!loading"
        class="w-full text-sm text-left rtl:text-right text-gray-500"
      >
        <thead class="text-xs text-gray-700 uppercase bg-[#96ffdf]">
          <tr>
            <th scope="col" class="px-6 py-3">Fecha</th>
            <th scope="col" class="px-6 py-3">Responsable</th>

            <th scope="col" class="px-6 py-3"></th>
          </tr>
        </thead>
        <tbody>
          <tr
            class="bg-white border-b hover:bg-[#96ffdf25]"
            v-for="(caja, index) in cajas"
            :key="index"
            @click="mostrarInfo(caja)"
          >
            <th
              scope="row"
              class="px-4 py-4 font-medium text-gray-900 whitespace-nowrap"
            >
              {{ caja.fechaApertura.substring(0, 10) }}
            </th>
            <td class="px-4 py-4">
              {{ caja.responsable }}
            </td>

            <td class="px-4 py-4">
              <a
                href="#"
                class="flex items-center font-medium text-blue-600 dark:text-blue-500"
                >Ver mas
                <svg
                  class="w-5 h-5 text-blue-600"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    <g id="Arrow / Chevron_Right_MD">
                      <path
                        id="Vector"
                        d="M10 8L14 12L10 16"
                        stroke="#000000"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                    </g>
                  </g></svg></a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div>
    <!--<ul
      v-if="!loading"
      class="divide-y divide-gray-200 bg-white shadow rounded-lg p-4"
    >
      <li
        v-for="(caja, index) in cajas"
        :key="index"
        @click="mostrarInfo(caja)"
        class="py-4 cursor-pointer hover:bg-gray-100 transition duration-300 ease-in-out rounded-lg"
      >
        <div class="flex items-center justify-between">
          <div class="text-sm">
            <p class="font-medium text-gray-900">
              {{ caja.fechaApertura.substring(0, 10) }}
            </p>
            <p class="text-gray-500">Responsable: {{ caja.responsable }}</p>
          </div>
          <i class="fas fa-chevron-right text-gray-400"></i>
        </div>
      </li>
    </ul>-->

    <!-- Modal -->
    <div
      v-if="cajaSeleccionada"
      class="fixed z-50 inset-0 overflow-y-auto"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div
        class="flex items-center justify-center min-h-screen px-4 text-center sm:block sm:p-0"
      >
        <div
          class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          aria-hidden="true"
          @click="cajaSeleccionada = null"
        ></div>
        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
          >&#8203;</span
        >
        <div
          class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
        >
          <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div class="flex sm:items-start justify-center">
              <div class="text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3
                  class="text-lg text-center leading-6 font-medium text-gray-900"
                  id="modal-title"
                >
                  Caja del {{ cajaSeleccionada.fechaApertura.substring(0, 10) }}
                </h3>
                <!--<div class="mt-5 space-y-2">
                  <ul class="list-disc list-inside">
                    <li>
                      <strong>Fecha Apertura:</strong>
                      {{ cajaSeleccionada.fechaApertura.substring(0, 10) }}
                    </li>
                    <li>
                      <strong>Responsable:</strong>
                      {{ cajaSeleccionada.responsable }}
                    </li>
                    <li>
                      <strong>Saldo Inicial:</strong> ${{
                        cajaSeleccionada.montoInicial.toFixed(2)
                      }}
                    </li>
                    <li>
                      <strong>Ingresos:</strong> ${{
                        cajaSeleccionada.totalImporteIngresos.toFixed(2)
                      }}
                    </li>
                    <li>
                      <strong>Egresos:</strong> ${{
                        cajaSeleccionada.totalImporteEgresos.toFixed(2)
                      }}
                    </li>
                    <li>
                      <strong>Cantidad de Lavados:</strong>
                      {{ cajaSeleccionada.cantidadLavados }}
                    </li>
                    <li>
                      <strong>Efectivo en Terminal:</strong> ${{
                        cajaSeleccionada.totalOtrosPagos.toFixed(2)
                      }}
                    </li>
                    <li>
                      <strong>Efectivo en Caja:</strong> ${{
                        cajaSeleccionada.efectivoEnCaja.toFixed(2)
                      }}
                    </li>
                    <li>
                      <strong>Declarado:</strong> ${{
                        cajaSeleccionada.efectivoDeclarado.toFixed(2)
                      }}
                    </li>
                    <li>
                      <strong>Descuadre:</strong> ${{
                        (
                          cajaSeleccionada.efectivoDeclarado -
                          cajaSeleccionada.efectivoEnCaja
                        ).toFixed(2)
                      }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            </div>-->

                <div class="mt-5 px-6 space-y-2">
                  <div class="relative overflow-x-auto">
                    <table
                      class="w-full text-sm text-left rtl:text-right text-gray-500"
                    >
                      <tbody>
                        <tr class="bg-white border-b hover:bg-[#96ffdf25]">
                          <th
                            scope="row"
                            class="px-6 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                          >
                            <strong>Fecha Apertura:</strong>
                          </th>
                          <td class="px-6 py-2">
                            {{
                              cajaSeleccionada.fechaApertura.substring(0, 10)
                            }}
                          </td>
                        </tr>

                        <tr class="bg-white border-b hover:bg-[#96ffdf25]">
                          <th
                            scope="row"
                            class="px-6 py-2 font-medium text-gray-900 whitespace-nowrap"
                          >
                            <strong>Responsable:</strong>
                          </th>
                          <td class="px-6 py-2">
                            {{ cajaSeleccionada.responsable }}
                          </td>
                        </tr>

                        <tr class="bg-white border-b hover:bg-[#96ffdf25]">
                          <th
                            scope="row"
                            class="px-6 py-2 font-medium text-gray-900 whitespace-nowrap"
                          >
                            <strong>Saldo inicial:</strong>
                          </th>
                          <td class="px-6 py-2">
                            ${{ cajaSeleccionada.montoInicial.toFixed(2) }}
                          </td>
                        </tr>

                        <tr class="bg-white border-b hover:bg-[#96ffdf25]">
                          <th
                            scope="row"
                            class="px-6 py-2 font-medium text-gray-900 whitespace-nowrap"
                          >
                            <strong>Ingresos:</strong>
                          </th>
                          <td class="px-6 py-2">
                            ${{
                              cajaSeleccionada.totalImporteIngresos.toFixed(2)
                            }}
                          </td>
                        </tr>

                        <tr class="bg-white border-b hover:bg-[#96ffdf25]">
                          <th
                            scope="row"
                            class="px-6 py-2 font-medium text-gray-900 whitespace-nowrap"
                          >
                            <strong>Egresos:</strong>
                          </th>
                          <td class="px-6 py-2">
                            ${{
                              cajaSeleccionada.totalImporteEgresos.toFixed(2)
                            }}
                          </td>
                        </tr>

                        <tr class="bg-white border-b hover:bg-[#96ffdf25]">
                          <th
                            scope="row"
                            class="px-6 py-2 font-medium text-gray-900 whitespace-nowrap"
                          >
                            <strong>Cantidad de Ventas:</strong>
                          </th>
                          <td class="px-6 py-2">
                            {{ cajaSeleccionada.cantidadLavados }}
                          </td>
                        </tr>

                        <tr class="bg-white border-b hover:bg-[#96ffdf25]">
                          <th
                            scope="row"
                            class="px-6 py-2 font-medium text-gray-900 whitespace-nowrap"
                          >
                            <strong>Efectivo en Terminal:</strong>
                          </th>
                          <td class="px-6 py-2">
                            ${{ cajaSeleccionada.totalOtrosPagos.toFixed(2) }}
                          </td>
                        </tr>

                        <tr class="bg-white border-b hover:bg-[#96ffdf25]">
                          <th
                            scope="row"
                            class="px-6 py-2 font-medium text-gray-900 whitespace-nowrap"
                          >
                            <strong>Efectivo en Caja:</strong>
                          </th>
                          <td class="px-6 py-2">
                            ${{ cajaSeleccionada.efectivoEnCaja.toFixed(2) }}
                          </td>
                        </tr>

                        <tr class="bg-white border-b hover:bg-[#96ffdf25]">
                          <th
                            scope="row"
                            class="px-6 py-2 font-medium text-gray-900 whitespace-nowrap"
                          >
                            <strong>Declarado:</strong>
                          </th>
                          <td class="px-6 py-2">
                            ${{ cajaSeleccionada.efectivoDeclarado.toFixed(2) }}
                          </td>
                        </tr>

                        <tr class="bg-white border-b hover:bg-[#96ffdf25]">
                          <th
                            scope="row"
                            class="px-6 py-2 font-medium text-gray-900 whitespace-nowrap"
                          >
                            <strong>Descuadre:</strong>
                          </th>
                          <td class="px-6 py-2">
                            ${{
                              (
                                cajaSeleccionada.efectivoDeclarado -
                                cajaSeleccionada.efectivoEnCaja
                              ).toFixed(2)
                            }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button
              type="button"
              class="w-full inline-flex justify-center text-white bg-[#3edfa9] hover:bg-[#ffe068] inline-flex items-center px-5 py-2.5 rounded-lg"
              @click="cajaSeleccionada = null"
            >
              Cerrar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import adminService from "../../composables/api/adminService.js";

export default {
  setup() {
    const cajas = ref([]);
    const loading = ref(false);

    const fechaDesde = ref("");
    const fechaHasta = ref("");
    const cajaSeleccionada = ref(null);

    const filtrarCajas = async () => {
      if (fechaDesde.value && fechaHasta.value) {
        loading.value = true;
        try {
          cajas.value = await adminService.getCajasPorFecha(
            fechaDesde.value,
            fechaHasta.value
          );
        } finally {
          loading.value = false;
        }
      }
    };

    function mostrarInfo(caja) {
      cajaSeleccionada.value = {
        ...caja,
        descuadre: caja.efectivoDeclarado - caja.efectivoEnCaja,
      };
    }

    return {
      cajas,
      fechaDesde,
      fechaHasta,
      cajaSeleccionada,
      mostrarInfo,
      filtrarCajas,
      loading,
    };
  },
  name: "CajasAdmin",
};
</script>

<style scoped>
/* Agrega cualquier estilo adicional que desees */
</style>
