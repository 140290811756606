import { ref } from 'vue';
import { API_URL } from '../../config.js';

const productoService = (() => {
  const productos = ref([]);
  const error = ref(null);
  const axios = require('axios');
  
  const instance = axios.create({
    baseURL: API_URL,
  });

  // Agregar interceptor para incluir token en las cabeceras
  instance.interceptors.request.use(
    config => {
      const token = localStorage.getItem('jwt-token');
      if (token) {
        config.headers['Authorization'] = 'Bearer ' + token;
      }
      return config;
    },
    error => {
      return Promise.reject(error);
    }
  );

  const getProductos = async () => {
    try {
      const response = await instance.get('/Producto');
      productos.value = response.data;
      return response.data;
    } catch (err) {
      console.error('Error al obtener productos:', err);
      error.value = err;
      return []; // Retornar una matriz vacía en caso de error
    }
  };

  const getProductoById = async (id) => {
    try {
      const response = await instance.get(`/Producto/${id}`);
      return response.data;
    } catch (error) {
      console.error('Error:', error);
      return null;
    }
  };

  const addProducto = async (producto) => {
    try {
      const response = await instance.post('/Producto', producto);
      productos.value.push(response.data);
      return response.data;
    } catch (error) {
      console.error('Error:', error);
      return null;
    }
  };

  const editProducto = async (producto) => {
    try {
      const response = await instance.put(`/Producto/${producto.id}`, producto);
      return response.data;
    } catch (error) {
      console.error('Error:', error);
      return null;
    }
  };

  const deleteProducto = async (productoId) => {
    try {
      const response = await instance.delete(`/Producto/${productoId}`);
      productos.value = productos.value.filter(p => p.id !== productoId);
      return response.data;
    } catch (error) {
      console.error('Error:', error);
      throw new Error('No se pudo eliminar el producto.');
    }
  };

  return {
    productos,
    error,
    getProductos,
    getProductoById,
    addProducto,
    editProducto,
    deleteProducto,
  };
})();

export default productoService;
