<template>

  <!--<Panel v-if="role && $route.name != 'Login' && $route.name == 'Landing'" :role="role">

    <router-view />

  </Panel>


  <router-view v-else />-->

  <div>
    <Panel v-if="role && $route.name != 'Login' && $route.name != 'LandingPage' " :role="role">
    <div class="p-4 lg:ml-8 sm:ml-64 md:ml-8 ">
      <div class="p-4">
        <div class="flex items-center justify-center">
          <router-view />
        </div>
      </div>
    </div>
  </Panel>
  <router-view v-else/>
</div>


</template>

<script setup>
import { ref, onMounted } from 'vue';
import Panel from './layout/panel.vue';
import authService from './composables/api/authService.js';
import { initFlowbite } from "flowbite";
import { useRoute } from 'vue-router';

const route = useRoute();  // Obtenemos la ruta actual

const user = ref({});
const role = ref('admin'); // Asumiendo que admin es el rol por defecto

const checkUser = async () => {
  try {

    if (route.name != 'LandingPage') {
      console.log(route.name)
      const currentUser = await authService.getCurrentUser();
      user.value = currentUser;
    }

    // role.value = currentUser.roles[0].toLowerCase() || 'guest'; // Ajustar el rol según la respuesta del servicio
  } catch (error) {
    console.error('Error al obtener el usuario actual:', error);
    // Si hay un error (ej., no hay un usuario logeado), asignar 'guest'
    // role.value = 'guest';
  }
};

onMounted(async () => {
  await checkUser();
  // console.log(role.value)
  initFlowbite();
});
</script>

<style></style>
