import { API_URL } from '../../config.js';

const userService = (() => {
  const axios = require('axios');

  const instance = axios.create({
    baseURL: API_URL,
    withCredentials: true, // Include cookies in requests
  });

  instance.interceptors.request.use(
    config => {
      const token = localStorage.getItem('jwt-token');
      if (token) {
        config.headers['Authorization'] = 'Bearer ' + token;
      }
      return config;
    },
    error => {
      return Promise.reject(error);
    }
  );

  const addUsuario = async (usuario) => {
    try {
      const response = await instance.post('/user/register', usuario);
      return response.data;
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  const getAllUsuarios = async () => {
    try {
      const response = await instance.get('/user');
      return response.data;
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  const getById = async (id) => {
    try {
      const response = await instance.get(`/user/${id}`);
      return response.data;
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  const editUsuario = async (id, usuario) => {
    try {
      const response = await instance.put(`/user/${id}`, usuario);
      return response.data;
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  const removeUsuario = async (id) => {
    try {
      const response = await instance.delete(`/user/${id}`);
      return response.data;
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  const toggleUserStatus = async (id, isActive) => {
    try {
      const response = await instance.put(`/user/toggle-status/${id}`, isActive, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      return response.data;
    } catch (error) {
      console.log(error);
      return null;
    }
  };




  return {
    addUsuario,
    getAllUsuarios,
    getById,
    editUsuario,
    removeUsuario,
    toggleUserStatus,

  };
})();

export default userService;
