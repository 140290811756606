<template>
    <div class="grid grid-flow-row gap-4">
      <div class="max-w-md p-6 bg-white border border-gray-200 rounded-lg shadow">
        <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900">
          Producto
        </h5>
  
        <form class="max-w-sm mx-auto">
          <div class="mb-5">
            <!-- Campo de nombre del producto -->
            <div class="form-group">
              <label for="nombre">Nombre del Producto</label>
              <input
                type="text"
                class="form-control mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-300 focus:ring focus:ring-blue-200"
                id="nombre"
                v-model="form.nombre"
                required
              />
            </div>
  
            <!-- Campo de descripción del producto -->
            <div class="form-group">
              <label for="descripcion">Descripción</label>
              <input
                type="text"
                class="form-control mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-300 focus:ring focus:ring-blue-200"
                id="descripcion"
                v-model="form.descripcion"
              />
            </div>
  
            <!-- Campo de categoría del producto -->
            <div class="form-group">
              <label for="categoria" class="text-black">Categoría</label>
              <select
                class="form-select form-input mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-300 focus:ring focus:ring-blue-200"
                id="categoria"
                v-model="form.categoriaId"
                required
              >
                <option value="">Seleccionar categoría</option>
                <option v-for="categoria in categorias" :key="categoria.id" :value="categoria.id">
                  {{ categoria.nombre }}
                </option>
              </select>
            </div>
  
            <!-- Campo de precio del producto -->
            <div class="form-group">
              <label for="precio">Precio</label>
              <input
                type="number"
                class="form-control mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-300 focus:ring focus:ring-blue-200"
                id="precio"
                min="0"
                v-model="form.precio"
                required
              />
            </div>
  
            <!-- Campo de stock del producto -->
            <div class="form-group">
              <label for="stock">Stock</label>
              <input
                type="number"
                class="form-control mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-300 focus:ring focus:ring-blue-200"
                id="stock"
                min="0"
                v-model="form.stock"
                required
              />
            </div>
  
          </div>
  
          <button
            type="submit"
            @click.prevent="submitForm"
            class="text-black bg-green-300 hover:bg-green-600 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center"
          >
            Guardar
          </button>
  
          <router-link :to="'/productos'" class="ml-5 font-medium text-green-500 hover:underline">
            Cancelar
          </router-link>
        </form>
      </div>
    </div>
  </template>
  
  <script>
  import { ref, onMounted } from "vue";
  import { useRouter, useRoute } from "vue-router";
  import productoService from "../../composables/api/productoService.js";
//   import categoriaService from "../../composables/api/categoriaService.js";
  
  export default {
    setup() {
      const router = useRouter();
      const route = useRoute();
      const form = ref({
        id: 0,
        nombre: "",
        descripcion: "",
        categoriaId: null,
        precio: null,
        stock: null,
      });
  
      const categorias = ref([]);
  
      const submitForm = async () => {
        if (form.value.id == 0) {
          await productoService.addProducto(form.value);
        } else {
          await productoService.editProducto(form.value);
        }
        router.push("/productos");
      };
  
      onMounted(async () => {
        // categorias.value = await categoriaService.getAllCategorias();
        const query = route.query;
        if (query.id) {
          form.value = await productoService.getProductoById(query.id);
        }
      });
  
      return {
        form,
        categorias,
        submitForm,
      };
    },
  };
  </script>
  