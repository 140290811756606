<template>
    <div class="grid grid-flow-row gap-4">
      <div class="max-w-md p-6 bg-white border border-gray-200 rounded-lg shadow">
        <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900">
          Cliente
        </h5>
  
        <form class="max-w-sm mx-auto" @submit="submitForm">
          <div class="mb-5">
            <div class="form-group">
              <label for="nombreApellido">Nombre y Apellido</label>
              <input type="text"
                class="form-control mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-300 focus:ring focus:ring-blue-200"
                id="nombreApellido" v-model="form.nombreApellido" required />
            </div>
  
            <div class="form-group">
              <label for="celular">Celular</label>
              <input type="text"
                class="form-control mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-300 focus:ring focus:ring-blue-200"
                id="celular" v-model="form.celular" />
            </div>
  
           
  
            <div class="form-group">
              <label for="email">Email</label>
              <input type="text"
                class="form-control mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-300 focus:ring focus:ring-blue-200"
                id="email" v-model="form.email" />
            </div>

            <div class="form-group">
              <label for="concepto">Concepto</label>
              <input type="text"
                class="form-control mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-300 focus:ring focus:ring-blue-200"
                id="concepto" v-model="form.concepto" required />
            </div>
  
            <div class="form-group">
              <label for="isActive">Activo</label>
              <input type="checkbox"
                class="form-control mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-300 focus:ring focus:ring-blue-200"
                id="isActive" v-model="form.isActive" />
            </div>
          </div>
          <button type="submit" @click.prevent="submitForm"
            class="text-black bg-green-300 hover:bg-green-600 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center">
            Guardar
          </button>
  
          <router-link :to="isAdmin ? '/clientes' : '/clientes'"
            class="ml-5 font-medium text-green-500 hover:underline">Cancelar</router-link>
        </form>
      </div>
    </div>
  </template>
  
  <script>
  import clienteService from "../../composables/api/clienteService.js";
  import { ref, onMounted } from "vue";
  import { useRouter, useRoute } from "vue-router";
  
  export default {
    setup() {
      const router = useRouter();
      const route = useRoute();
      let isAdmin = ref(false);
  
      let form = ref({
        id: 0,
        nombreApellido: "",
        celular: null,
        // telefonoFijo: "",
        email: null,
        concepto: "",
        isActive: true
      });
  
      const submitForm = async () => {
        if (form.value.id === 0) {
          await clienteService.addCliente(form.value);
        } else {
          await clienteService.updateCliente(form.value.id, form.value);
        }
  
        form.value = {
          id: 0,
          nombreApellido: "",
          celular: null,
          // telefonoFijo: "",
          email: "",
          concepto: "",
          isActive: true,
        };
  
        router.push(isAdmin.value ? "/clientes" : "/clientes");
      };
  
      onMounted(async () => {
        const query = router.currentRoute.value.query;
  
        if (query.isAdmin === "true") {
          isAdmin.value = true;
        }
  
        if (query.id != null) {
          form.value.id = query.id;
          const cliente = await clienteService.getClienteById(query.id);
          form.value = { ...form.value, ...cliente };
        }
      });
  
      return {
        form,
        submitForm,
        isAdmin,
      };
    },
  };
  </script>
  