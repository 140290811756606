<template>
<div class="grid grid-flow-row gap-4">
    <div
      class="max-w-md p-6 bg-white border border-gray-200 rounded-lg shadow "
    >
      <h5
        class="mb-2 text-2xl text-center font-bold tracking-tight text-gray-900 "
      >
      Cerrar caja
      </h5>
      <div class="flex items-center mb-4 text-sm text-green-800" role="alert">
      <svg
        class="flex-shrink-0 inline w-4 h-4 me-3"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        viewBox="0 0 20 20"
      >
        <path
          d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"
        />
      </svg>
      <span class="sr-only">Info</span>
      <div>
        Aqui puede ingresar el dinero que se encuentra en efectivo en la caja. [No transferencias ni tarjetas]
      </div>
    </div>

      <form class="max-w-sm mx-auto" >
        <div class="mb-5">
          <div class="form-group">
            <label for="nomto">Declarar Efectivo:</label>
            <input
              type="number"
              class="form-control mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-300 focus:ring focus:ring-blue-200"
              id="efectivoDeclarado"
              v-model="formData.efectivoDeclarado"
              required
            />
          </div>
        </div>
        <button
          type="button"
          @click="CerrarCaja"
          class="text-black bg-green-300 hover:bg-green-600 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center "
        >
        Cerrar caja
        </button>

        <router-link
          :to="'/caja'"
          class="ml-5 font-medium text-green-500  hover:underline"
          >Cancelar</router-link
        >
      </form>
    </div>
  </div>
</template>



<script>
import { reactive, onMounted, ref } from 'vue';

import cajaService from '../../composables/api/cajaService.js';
import { useRouter, useRoute } from 'vue-router';

export default {
  name: 'CerrarCaja',
  setup() {
    const router = useRouter();
    const route = useRoute();
    let cajaAbierta = ref({});

    const formData = reactive({
      efectivoDeclarado: 0,
    });

    const navigateTo = (route) => {
      router.push(route);
    };


    const CerrarCaja = async () => {
      await cajaService
        .cerrarCaja(cajaAbierta.value.id, formData)
        .then((e) => console.log(e));
      // Reiniciamos los valores del formulario
      formData.efectivoDeclarado = '';
      setTimeout(function () {
        // location.reload();
      }, 1000);
      router.push('/abrircaja');
      //router.replace('/abrircaja');

    };

    onMounted(async () => {
      cajaAbierta.value = await cajaService.getCajaAbierta();
    });

    return {
      formData,
      CerrarCaja,
      cajaAbierta,
      navigateTo
    };
  },
};
</script>

<style>
/* Estilos de Bootstrap */
</style>
